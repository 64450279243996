import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import About from "../components/trialLesson/About"
import ApplicationButton from "../components/trialLesson/ApplicationButton"
import LessonFlow from "../components/trialLesson/LessonFlow"

const trialLesson = () => {
  return (
    <Layout>
      <Seo title="体験レッスン" />
      <div className="mt-4 md:mt-0">
        <StaticImage src="../images/hero2.png" alt="hero image" />
      </div>
      <LessonFlow />
      <About />
      <div className="mt-16">
        <ApplicationButton />
      </div>
    </Layout>
  )
}

export default trialLesson
