import React from "react"

export const applicationUrl =
  "https://squareup.com/appointments/book/j3zsftkg7u1klf/LRWVYSFRVA4GW/services"

const ApplicationButton = () => {
  return (
    <div>
      <a
        href={applicationUrl}
        target="_blank"
        className="block px-5 py-2 mx-auto font-semibold rounded bg-Yellow text-DeepGreen w-fit md:px-7"
      >
        体験レッスンを予約する
      </a>
    </div>
  )
}

export default ApplicationButton
