import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import ApplicationButton from "./ApplicationButton"

const About = () => {
  const list = [
    "約50分",
    "カウンセリング、実際のミニレッスン、質疑応答",
    "持ち物不要・事前準備不要",
  ]
  return (
    <div
      className="bg-LightYellow"
      style={{
        margin: `4rem calc(50% - 50vw) 0`,
      }}
    >
      <div className="max-w-4xl px-4 mx-auto">
        <div className="items-center py-10 md:py-16">
          <p className="text-xl font-bold text-center text-red-400">
            無料キャンペーン実施中!!
          </p>
          <div className="flex items-center justify-center gap-4 mt-4">
            <s className="font-bold">1回 1,000円</s>
            <p className="text-xl font-bold text-red-400">0円</p>
          </div>
          <div className="mt-8">
            <ApplicationButton />
          </div>
          <div className="max-w-xl p-5 mx-auto mt-8 border-2 md:mt-16 border-Yellow">
            {list.map(item => (
              <div className="flex items-center" key={item}>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="mr-2"
                  color="#183A1D"
                />
                <div>{item}</div>
              </div>
            ))}
          </div>
          <div className="mt-10 md:mt-16">
            始めに雑談形式でカウンセリング・ヒアリングをした後、その場でミニレッスンを行います。
            実際にWombat
            Englishのレッスンで取り入れている外国語学習の手法である、タスクベース・ラーニング（TBL）を体験していただくことができます。質問があればレッスン中になんでもお尋ねください！
            <br />
            <br />
            英会話をこれから始めたい方、レベルアップしたい方、受験勉強のために使いたい方、どなたでもご興味があればぜひお気軽に体験レッスンからお申し込みください。
            Wombat Englishがみなさんの英会話学習のお手伝いをさせていただきます。
          </div>
          <div className="w-32 mx-auto mt-8 md:w-40 md:mt-16">
            <StaticImage src="../../images/globe.png" alt="globe" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
