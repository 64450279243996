import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import HeadlineM from "../HeadlineM"
import { applicationUrl } from "./ApplicationButton"
import Annotation from "./Annotation"

const LessonFlow = () => {
  return (
    <div className="mt-8 md:mt-16">
      <HeadlineM title="体験レッスン" />
      <Annotation />
      <div className="grid-cols-11 mt-8 md:mt-16 md:grid">
        <div className="col-span-3 p-5 text-xl text-center bg-LightGreen">
          <a
            href={applicationUrl}
            target="_blank"
            className="underline hover:no-underline"
          >
            予約ページ
          </a>
          <br />
          からお申し込み
        </div>
        <div className="flex p-3 mx-auto rotate-90 w-fit md:rotate-0 md:p-0">
          <StaticImage
            src="../../images/allow-yellow.png"
            alt="allow yellow"
            className="w-10 mx-auto"
            objectFit="contain"
          />
        </div>
        <div className="col-span-3 p-5 text-xl text-center bg-LightGreen">
          カウンセリング＆
          <br />
          スケジュール確定
        </div>
        <div className="flex p-3 mx-auto rotate-90 w-fit md:rotate-0 md:p-0">
          <StaticImage
            src="../../images/allow-yellow.png"
            alt="allow yellow"
            className="w-10 mx-auto"
            objectFit="contain"
          />
        </div>
        <div className="col-span-3 p-5 text-xl text-center bg-LightGreen">
          体験レッスン
          <br />
          受講
        </div>
      </div>
    </div>
  )
}

export default LessonFlow
