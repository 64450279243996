import { Link } from "gatsby"
import React from "react"
import { PATH } from "../../constants"

const Annotation = () => {
  return (
    <>
      <div className="max-w-xl p-5 mx-auto mt-4 border-2 md:mt-8 border-Yellow">
        夕方の時間は通常レッスンが集中しているため空きがない場合があります。予約ページで選択できる日時がない場合は、
        <Link to={PATH.CONTACT} className="underline hover:no-underline">
          お問い合わせ
        </Link>
        &nbsp;からメッセージを送信ください。
      </div>
    </>
  )
}

export default Annotation
